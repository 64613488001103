export default [
  {
    path: '/Login',
    meta: { layout: "default", guest: true, noScroll: true },
    name: 'Login',
    component: () => import('@/pages/Login.vue')
  },
  {
    path: '/',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true },
    name: 'Root',
    component: () => import('@/pages/Landing.vue')
  },
  {
    path: '*',
    meta: { layout: "default", guest: true, noScroll: true },
    name: 'Deny',
    component: () => import('@/pages/Deny.vue')
  },
  {
    path: '/404',
    name: 'AccessDenied',
    meta: { layout: "default", guest: true, noScroll: true },
    component: () => import('@/pages/Deny.vue')
  },
  {
    path: '/changePassword',
    name: 'ChangePassword',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true, vendor: true },
    component: () => import('@/pages/ChangePassword.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true, vendor: true },
    component: () => import('@/pages/Profile.vue')
  },
  {
    path: '/organizations',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true },
    name: 'OrganizationIndex',
    component: () => import('@/pages/organizations/index.vue')
  },
  {
    path: '/organization/create',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true },
    name: 'OrganizationCreate',
    component: () => import('@/pages/organizations/create.vue')
  },
  {
    path: '/organization/:id/edit',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true },
    name: 'OrganizationEdit',
    component: () => import('@/pages/organizations/edit.vue')
  },
  {
    path: '/users',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true },
    name: 'UserIndex',
    component: () => import('@/pages/users/index.vue')
  },
  {
    path: '/user/:id/edit',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true },
    name: 'UserEdit',
    component: () => import('@/pages/users/edit.vue')
  },
  {
    path: '/log/sms/esms',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'ESMSSMSLog',
    component: () => import('@/pages/logger/esmsSmsLog.vue')
  },
  {
    path: '/log/sms/nexmo',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'NexmoSMSLog',
    component: () => import('@/pages/logger/nexmoSmsLog.vue')
  },
  {
    path: '/log/email/ses',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'SESEmailLog',
    component: () => import('@/pages/logger/sesEmailLog.vue')
  },
  {
    path: '/surveygizmo/update',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'UpdateSGResponse',
    component: () => import('@/pages/surveygizmo/update.vue')
  },
]