export default [
  {
    path: '/mobile/users',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileUserIndex',
    component: () => import('@/pagesMobile/Users/index.vue')
  },
  {
    path: '/mobile/communityusers',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'CommunityUsersIndex',
    component: () => import('@/pagesMobile/Community/index.vue')
  },
  {
    path: '/mobile/user/:id/profile/',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileUserProfile',
    component: () => import('@/pagesMobile/Users/profile.vue')
  },
  {
    path: '/mobile/project',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileProjectIndex',
    component: () => import('@/pagesMobile/Projects/index.vue')
  },
  {
    path: '/mobile/project/:id/edit',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileProjectEdit',
    component: () => import('@/pagesMobile/Projects/edit.vue')
  },
  {
    path: '/mobile/project/create',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileProjectCreate',
    component: () => import('@/pagesMobile/Projects/create.vue')
  },
  {
    path: '/mobile/project/:id/status',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileProjectStatus',
    component: () => import('@/pagesMobile/Projects/status.vue')
  },
  {
    path: '/mobile/activity/create',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileActivityCreate',
    component: () => import('@/pagesMobile/Activities/create.vue')
  },
  {
    path: '/mobile/activites',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileActivityIndex',
    component: () => import('@/pagesMobile/Activities/index.vue')
  },
  {
    path: '/mobile/activity/:id/edit',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileActivityEdit',
    component: () => import('@/pagesMobile/Activities/edit.vue')
  },
  {
    path: '/mobile/rewards',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileRewardIndex',
    component: () => import('@/pagesMobile/Rewards/index.vue')
  },
  {
    path: '/mobile/reward/:id/edit',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileRewardEdit',
    component: () => import('@/pagesMobile/Rewards/edit.vue')
  },
  {
    path: '/mobile/cashout',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileCashoutIndex',
    component: () => import('@/pagesMobile/Cashout/index.vue')
  },
  {
    path: '/mobile/cashout/bulkUpdate',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileCashoutBulkUpdate',
    component: () => import('@/pagesMobile/Cashout/bulkUpdate.vue')
  },
  {
    path: '/mobile/cashout/:id/edit',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileCashoutEdit',
    component: () => import('@/pagesMobile/Cashout/edit.vue')
  },
  {
    path: '/mobile/project/assign',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileAssignProject',
    component: () => import('@/pagesMobile/ProjectAssign/index.vue')
  },
  {
    path: '/mobile/userActivity',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileUserActivityIndex',
    component: () => import('@/pagesMobile/UserActivity/index.vue')
  },
  {
    path: '/mobile/userActivity/:userId/:projectId/edit',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileUserActivityEdit',
    component: () => import('@/pagesMobile/UserActivity/edit.vue')
  },
  {
    path: '/activity/view/:userId/:projectId/:hashTimestamp',
    meta: {
      layout: "default", renderNavBar: false, renderSideBar: false, guest: true
    },
    name: 'PublicMobileUserActivityEdit',
    component: () => import('@/pagesMobile/UserActivity/edit.vue')
  },
  {
    path: '/mobile/notification',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileNotification',
    component: () => import('@/pagesMobile/Notification/index.vue')
  },
  {
    path: '/mobile/projectCode/create',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileProjectCodeCreate',
    component: () => import('@/pagesMobile/ProjectCode/create.vue')
  },
  {
    path: '/mobile/projectCode/:id/edit',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileProjectCodeEdit',
    component: () => import('@/pagesMobile/ProjectCode/edit.vue')
  },
  {
    path: '/mobile/ProjectCode',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileProjectCodeIndex',
    component: () => import('@/pagesMobile/ProjectCode/index.vue')
  },
  {
    path: '/mobile/ProjectCode/assign',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileProjectCodeAssign',
    component: () => import('@/pagesMobile/ProjectCode/assign.vue')
  },
  {
    path: '/mobile/audience/lookup',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileAudienceLookup',
    component: () => import('@/pagesMobile/Audience/lookup.vue')
  },
  {
    path: '/mobile/qc',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileQualityControlIndex',
    component: () => import('@/pagesMobile/QualityControl/index.vue')
  },
  {
    path: '/mobile/multimedia/create',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileMultimediaCreate',
    component: () => import('@/pagesMobile/Multimedia/create.vue')
  },
  {
    path: '/mobile/multimedia',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileMultimediaIndex',
    component: () => import('@/pagesMobile/Multimedia/index.vue')
  },
  {
    path: '/mobile/multimedia/:multimediaId/edit',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileMultimediaEdit',
    component: () => import('@/pagesMobile/Multimedia/edit.vue')
  },
  {
    path: '/mobile/socialSharing',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileSocialSharingIndex',
    component: () => import('@/pagesMobile/SocialSharing/index.vue')
  },
  {
    path: '/mobile/socialSharing/:sharingId/:userId/edit',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileSocialSharingEdit',
    component: () => import('@/pagesMobile/SocialSharing/edit.vue')
  },
  {
    path: '/mobile/referralCode/status',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileProjectCodeStatus',
    component: () => import('@/pagesMobile/ProjectCode/status.vue')
  },
  {
    path: '/mobile/userProfiling',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileUserProfileIndex',
    component: () => import('@/pagesMobile/UserProfiling/index.vue')
  },
  {
    path: '/mobile/userProfiling/:userId/:userProfilingId/submissions',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileUserProfileSubmissions',
    component: () => import('@/pagesMobile/UserProfiling/submissions.vue')
  },
  {
    path: '/mobile/userProfiling/create',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileUserProfileCreate',
    component: () => import('@/pagesMobile/UserProfiling/create.vue')
  },
  {
    path: '/mobile/userProfiling/:userProfilingId/edit',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileUserProfileEdit',
    component: () => import('@/pagesMobile/UserProfiling/edit.vue')
  },
  {
    path: '/mobile/ooh/brand',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileOohBrandData',
    component: () => import('@/pagesMobile/OohData/brand.vue')
  },
  {
    path: '/mobile/ooh/store',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileOohStoreData',
    component: () => import('@/pagesMobile/OohData/store.vue')
  },
  {
    path: '/mobile/qc/places',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileQcPlaces',
    component: () => import('@/pagesMobile/QualityControl/places.vue')
  },
  {
    path: '/mobile/cashout/paypal',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'CashoutPaypal',
    component: () => import('@/pagesMobile/CashoutPaypal/index.vue')
  },
  {
    path: '/mobile/misc/smsGateway',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MobileSMSGateway',
    component: () => import('@/pagesMobile/Misc/smsGateway.vue')
  },
  {
    path: '/mobile/cashout/plantTree',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'CashoutPlantTree',
    component: () => import('@/pagesMobile/PlantTree/index.vue')
  },

  {
    path: '/mobile/moo/index',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'MooIndex',
    component: () => import('@/pages/moo/index.vue')
  },
  {
    path: '/mobile/users/userCount',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true },
    name: 'users',
    component: () => import('@/pagesMobile/Users/usercount.vue')
  },
  {
    path: '/mobile/users/userFilter',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true },
    name: 'UserFilter',
    component: () => import('@/pagesMobile/Users/userfilter.vue')
  },
  {
    path: '/mobile/ipurchase/index',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'ipurchase',
    component: () => import('@/pagesMobile/iPurchase/index.vue')
  },

  {
    path: '/mobile/ipurchase/:id/details/',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true },
    name: 'ipurchasedetails',
    component: () => import('@/pagesMobile/iPurchase/details.vue')
  },
  {
    path: '/mobile/search/activeusers',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'ActiveUsers',
    component: () => import('@/pagesMobile/Audience/activeUsers.vue')
  },
  {
    path: '/mobile/survey/assign',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'SurveyAssign',
    component: () => import('@/pagesMobile/AssignSurvey/index.vue')
  },
]