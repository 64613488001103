export default [
  {
    path: '/buyer/createUser',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'BuyerCreateUser',
    component: () => import('@/pagesBuyerDashboard/Users/create.vue')
  },
  {
    path: '/buyer/:id/edit',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'BuyerEditUser',
    component: () => import('@/pagesBuyerDashboard/Users/edit.vue')
  },
  {
    path: '/buyer/users',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'BuyerUsers',
    component: () => import('@/pagesBuyerDashboard/Users/index.vue')
  },
  {
    path: '/buyer/projects',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'BuyerProjects',
    component: () => import('@/pagesBuyerDashboard/Projects/index.vue')
  },
  {
    path: '/buyer/createProjects',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'BuyerCreateProjects',
    component: () => import('@/pagesBuyerDashboard/Projects/create.vue')
  },
  {
    path: '/buyer/configureProject/:projectId',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'ConfigureProjects',
    component: () => import('@/pagesBuyerDashboard/Projects/configureProject.vue')
  },
  {
    path: '/buyer/respondants',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'BuyerRespondants',
    component: () => import('@/pagesBuyerDashboard/Respondents/Respondants.vue')
  },
  {
    path: '/buyer/manageRespondents/:respondentId',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'BuyerManageRespondents',
    component: () => import('@/pagesBuyerDashboard/Respondents/ManageRespondents.vue')
  },
  {
    path: '/buyer/virtualQues/:projectId',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'BuyerVirtualQues',
    component: () => import('@/pagesBuyerDashboard/Projects/VirtualQues.vue')
  },
  {
    path: '/buyer/syncRespondents',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'BuyerSyncRespondents',
    component: () => import('@/pagesBuyerDashboard/Respondents/SyncRespondents.vue')
  },
  {
    path: '/buyer/assign',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'BuyerAssign',
    component: () => import('@/pagesBuyerDashboard/Projects/assign.vue')
  },
  {
    path: '/buyer/quotations',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'Quotation',
    component: () => import('@/pagesBuyerDashboard/Quotation/index.vue')
  },
  {
    path: '/buyer/quotationDetail/:id',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'QuotationDetail',
    component: () => import('@/pagesBuyerDashboard/Quotation/detail.vue')
  },
]