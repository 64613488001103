import axios from "axios";
import Util from "../../Util.js"

var ADMIN_BACKEND_URL = process.env.VUE_APP_ADMIN_BACKEND_URL;

export default class RestResource {
  async login(loginDto) {
    const url = `${ADMIN_BACKEND_URL}/vendor/login`
    return await axios.post(url, loginDto)
  }

  async logout() {
    const url = `${ADMIN_BACKEND_URL}/vendor/logout`
    axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')
    return await axios.post(url)
  }

  async getProjects(params) {
    axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')
    const url = `${ADMIN_BACKEND_URL}/vendor/getProjects`;
    return await axios.post(url, params);
  }

  async getTotalUserCount(params) {
    const url = `${ADMIN_BACKEND_URL}/vendor/countTotalUsers`
    return await axios.post(url, params, Util.setupHeaders())
  }

  async getProjectUsers(data) {
    const url = `${ADMIN_BACKEND_URL}/vendor/getProjectUsers`
    return await axios.post(url, data, Util.setupHeaders())
  }

  async findUserByText(data){
    const url = `${ADMIN_BACKEND_URL}/vendor/findUserByText`
    return await axios.post(url, data, Util.setupHeaders())
}

  async getOrganization(params) {
    const url = `${ADMIN_BACKEND_URL}/vendor/getOrganization`
    return await axios.post(url, params, Util.setupHeaders())
  }

  async getUserProfilingCompletes(params) {
    const url = `${ADMIN_BACKEND_URL}/vendorProfiling/getUPCount`
    return await axios.post(url, params, Util.setupHeaders());
  }

  async getLastUserJoinedDate(params) {
    const url = `${ADMIN_BACKEND_URL}/vendor/getLastUserJoinedDate`
    return await axios.post(url, params, Util.setupHeaders())
  }

  async exportUsersByProjectCodes(data) {
    const url = `${ADMIN_BACKEND_URL}/vendor/export/user/projectCodes`
    return await axios.post(url, data, Util.setupHeaders())
  }

  async exportUsersByUserActivity(data) {
    const url = `${ADMIN_BACKEND_URL}/vendor/export/user/userActivity`
    return await axios.post(url, data, Util.setupHeaders())
  }

  async countActiveUsers(data) {
    const url = `${ADMIN_BACKEND_URL}/vendor/countActiveUsers`
    return await axios.post(url, data, Util.setupHeaders())
  }

  async fetchActivities(params) {
    const url = `${ADMIN_BACKEND_URL}/vendor/fetchActivities`
    return await axios.post(url, params, Util.setupHeaders())
  }

  async fetchUserActivities(data) {
    const url = `${ADMIN_BACKEND_URL}/vendor/fetchUserActivities`
    return await axios.post(url, data, Util.setupHeaders())
  }

  // TODO: route does not exists in backend
  async updateUser(data) {
    const url = `${ADMIN_BACKEND_URL}/vendor/updateProfile`
    return await axios.post(url, data, Util.setupHeaders())
  }

  async getConfig(key) {
    const url = `${ADMIN_BACKEND_URL}/common/data/getConfig?key=${key}`
    return await axios.get(url)
  }

  async createUser(user) {
    const url = `${ADMIN_BACKEND_URL}/vendor/createUser`
    return await axios.post(url, user, Util.setupHeaders());
  }

  async getCardData(data) {
    const url = `${ADMIN_BACKEND_URL}/vendorProfiling/getCardData`
    return await axios.post(url, data, Util.setupHeaders());
  }

  async checkIncompletes(data) {
    const url = `${ADMIN_BACKEND_URL}/vendorProfiling/checkIncompletes`
    return await axios.post(url, data, Util.setupHeaders());
  }

  async getAllusers(data) {
    const url = `${ADMIN_BACKEND_URL}/vendorProfiling/getAllUsers`
    return await axios.post(url, data, Util.setupHeaders());
  }

  async checkUserStatus(data) {
    const url = `${ADMIN_BACKEND_URL}/vendorProfiling/checkUserStatus`
    return await axios.post(url, data, Util.setupHeaders());
  }

  // TODO: Need to add back
  // async filterByDate(data) {
  //   const url = `${ADMIN_BACKEND_URL}/vendorProfiling/filterByDate`
  //   return await axios.post(url, data, Util.setupHeaders());
  // }

  async getVendorCodes(data) {
    const url = `${ADMIN_BACKEND_URL}/vendor/getVendorCodes`
    return await axios.post(url, data, Util.setupHeaders());
  }

  async getProjectFromCode(data) {
    const url = `${ADMIN_BACKEND_URL}/vendor/getProjectFromCode`
    return await axios.post(url, data, Util.setupHeaders());
  }

  async viewUserActivities(params) {
    const url = `${ADMIN_BACKEND_URL}/vendor/viewUserActivities`
    return await axios.post(url, params, Util.setupHeaders())
  }

  async fetchUserActivityDataCustom(params) {
    const url = `${ADMIN_BACKEND_URL}/vendor/viewUserActivitiesCustom`
    return await axios.post(url, params, Util.setupHeaders())
  }
}
