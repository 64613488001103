const MobileMenuv2 =  [
    {
      title: 'Users', name: 'MobileV2Users', icon: 'supervised_user_circle',
    },
    {
      title: 'Task Status', name: 'MobileV2TaskStatus', icon: 'task_alt',
    },
    {
      title: 'Task Notify', name: 'MobileV2TaskNotify', icon: 'notifications',
    },
    {
      title: 'Receipts', name: 'MobileV2Receipts', icon: 'receipt',
    },
    {
      title: 'Tasks', name: 'MobileV2Tasks', icon: 'assignment',
    },
    {
      title: 'Cashouts', name: 'MobileV2Cashout', icon: 'money',
    },
    {
      title: 'Deals', name: 'MobileV2Deals', icon: 'money',
    },
    {
      title: 'Assign Task', name: 'MobileV2AssignTask', icon: 'assignment',
    },
    {
      title: 'I Consume', name: 'MobileV2IConsume', icon: 'assignment',
    },
    {
      title: 'Issues', name: 'MobileV2Issues', icon: 'assignment',
    },
  ];
  // reorder menu
  MobileMenuv2.forEach((item) => {
    if (item.items) {
      item.items.sort((x, y) => {
        let textA = x.title.toUpperCase();
        let textB = y.title.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    }
  });
  
  export default MobileMenuv2;
  