<template>
  <v-app-bar color="primary" flat dark app>
    <!-- Drawer Toggle Button -->
    <v-btn icon @click.stop="handleDrawerToggle" class="ml-2">
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <!-- Logo -->
    <v-img
      src="./../assets/images/logo.png"
      class="logo"
      contain
      height="40"
      width="60"
      v-show="$vuetify.breakpoint.smAndUp"
      @click="$router.push({ name: 'Root' })"
    ></v-img>

    <v-spacer></v-spacer>

    <!-- Admin Toggle Button -->
    <v-btn
      small
      text
      @click="toggleView()"
      v-if="isAdmin()"
      class="mr-2"
    >
      {{ $store.state.loggedInAs == 'VENDOR' ? "View As Admin" : "View As Vendor" }}
    </v-btn>

    <!-- Full Screen Button with Tooltip -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon @click="handleFullScreen" v-show="$vuetify.breakpoint.smAndUp" v-on="on">
          <v-icon>{{ fullScreenIcon }}</v-icon>
        </v-btn>
      </template>
      <span>Toggle Full Screen</span>
    </v-tooltip>

    <!-- Avatar Menu with Modernized Look -->
    <v-menu
      offset-y
      origin="center center"
      :nudge-bottom="10"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-avatar size="40" color="primary" v-on="on">
          <v-icon>mdi-account-circle</v-icon>
        </v-avatar>
      </template>

      <v-list dense>
        <v-list-item
          v-for="(item, index) in items"
          :to="!item.href ? { name: item.name } : item.href"
          :href="item.href"
          @click="item.click"
          ripple
          rel="noopener"
          :key="index"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      fullScreenIcon: 'mdi-fullscreen',
      items: [
        {
          icon: "mdi-account-circle",
          name: "Profile",
          title: "Profile",
          click: () => {},
        },
        {
          icon: "settings",
          name: "ChangePassword",
          title: "Change Password",
          click: () => {},
        },
        {
          icon: 'mdi-logout',
          href: '#',
          title: 'Logout',
          click: () => { window.getApp.$emit('APP_LOGOUT') }
        },
      ],
    };
  },
  methods: {
    handleDrawerToggle() {
      window.getApp.$emit('APP_DRAWER_TOGGLED');
    },
    toggleView() {
      const role = (this.$store.state.loggedInAs === 'VENDOR') ? 'ADMIN' : 'VENDOR';
      this.$store.state.loggedInAs = role;
      window.$cookies.set('izcRole', role);
      this.$router.push({ name: role === 'ADMIN' ? 'Root' : 'VendorDashboard' });
    },
    handleFullScreen() {
      this.$store.state.fullScreen = !this.$store.state.fullScreen;
      this.$store.state.fullScreen ? this.openFullScreen() : this.closeFullScreen();
      this.fullScreenIcon = this.$store.state.fullScreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen';
    },
    openFullScreen() {
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullScreen) {
        elem.webkitRequestFullScreen();
      } else if (elem.msRequestFullScreen) {
        elem.msRequestFullScreen();
      }
    },
    closeFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullScreen) {
        document.webkitExitFullScreen();
      } else if (document.msExitFullScreen) {
        document.msExitFullScreen();
      }
    },
    isAdmin() {
      const localUser = this.$store.state.loggedInAs === 'ADMIN' ? this.$store.state.adminUser : this.$store.state.vendorUser;
      return localUser && localUser.roles && localUser.roles.includes('ADMIN');
    },
  },
};
</script>

<style scoped>
.logo {
  cursor: pointer;
}

.v-btn {
  padding: 0 12px;
}

.v-avatar {
  cursor: pointer;
  background-color: var(--v-primary-base);
}

.v-list-item {
  padding: 8px 16px;
  font-size: 14px;
}

.v-list-item-action {
  margin-right: 12px;
}

.v-list-item-title {
  font-weight: 500;
}

.v-app-bar {
  background: linear-gradient(135deg, #007bff, #00c6ff);
}
</style>
