import Vue from 'vue'
import Router from 'vue-router'
import paths from './paths'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from './../app.store'
import DataService from './../services/dataServiceAdmin'

let dataService = new DataService()

// Ignore navigating to same path
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(Router)

const router = new Router({
  base: '/',
  mode: 'history',
  linkActiveClass: 'active',
  routes: paths
});

router.beforeEach((to, from, next) => {
  NProgress.start();

  if (to.matched.some(record => record.meta.guest)) {
    next()
  } else {
    if (window.$cookies.get('izcToken') == null) {
      next({ name: 'Login' })
    } else {
      store.state.loggedInAs = window.$cookies.get('izcRole')
      
      // Extend session for another hour
      window.$cookies.set('izcToken', window.$cookies.get('izcToken'))
      window.$cookies.set('izcRole', window.$cookies.get('izcRole'))

      if (window.$cookies.get('izcRole') == "ADMIN") {
        if (Object.keys(store.state.adminUser).length == 0 || Object.keys(store.state.userProfile).length == 0) {
          store.state.loader = true

          dataService.whoAmI().then(res => { 
            store.state.adminUser = res.data.localUser
            store.state.userProfile = res.data.user
            store.state.loader = false

            next()
          }).catch(() => {
            window.$cookies.set('izcRole', "VENDOR")
            window.location.reload()
          })
        } else {
          next()
        }
      } else if (window.$cookies.get('izcRole') == "VENDOR") {
        if (to.matched.some(record => record.meta.vendor)) {
          if (Object.keys(store.state.vendorUser).length == 0 || Object.keys(store.state.userProfile).length == 0) {
            store.state.loader = true
  
            dataService.whoAmI({ type: 'vendor' }).then(res => { 
              store.state.vendorUser = res.data.localUser
              store.state.userProfile = res.data.user
              store.state.loader = false
              
              next()
            }).catch(() => {
              window.$cookies.set('izcRole', "ADMIN")
              window.location.reload()
            })
          } else {
            next()
          }
        } else {
          next({ name: 'VendorDashboard' })
        }
      } else {
        window.$cookies.remove('izcToken')
        next({ name: 'Login' })

        throw new Error("ROLE NOT FOUND")
      }
    }
  }
})

router.afterEach(() => {
  NProgress.done();
});

export default router;