export default [
    {
      path: '/website/enquiry',
      meta: { layout: "default", renderNavBar: true, renderSideBar: true },
      name: 'WebsiteEnquiry',
      component: () => import('@/pages/website/Enquiry/index.vue')
    },
    {
      path: '/website/community/user',
      meta: { layout: "default", renderNavBar: true, renderSideBar: true },
      name: 'CommunityUsers',
      component: () => import('@/pages/website/CommunityUsers/index.vue')
    },
]