<template>
  <v-navigation-drawer v-model="drawer" app width="300">
    <v-list-item class="my-1">
      <v-list-item-avatar>
        <v-avatar size="48" tile>
          <v-btn fab small dark>
            <span class="white--text headline">{{ nameToInitials }}</span>
          </v-btn>
        </v-avatar>
      </v-list-item-avatar>

      <v-list-item-title><h3>{{ $store.state.userProfile.name }}</h3></v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item v-for="item in taskItems" :key="item.title" @click="$router.push({ name: item.route })" link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: true,
      taskItems: [
        { title: "Users", icon: "supervised_user_circle", route: "VendorDashboard" },
        { title: "User Profiling", icon: "source", route: "VendorProfiling" },
        { title: "User Activity", icon: "person", route: "VenderUserActivity" },
      ],
    }
  },
  created() {
    window.getApp.$on('APP_DRAWER_TOGGLED', () => {
      this.drawer = (!this.drawer);
    });
  },
  computed: {
    nameToInitials() {
      try {
        let name = this.$store.state.userProfile.name
        return name.split(" ").map(x => x.charAt(0)).join("").substr(0, 2).toUpperCase();
      } catch (error) {
        return "IZ"
      }
    }
  }
};
</script>