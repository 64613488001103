export default [
  {
    path: '/vendor/createUser',
    meta: {
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'VendorCreateUser',
    component: () => import('@/pagesVendor/Users/create.vue')
  },
  {
    path: '/vendor/users',
    meta: { 
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'VendorUsers',
    component: () => import('@/pagesVendor/Users/index.vue')
  },
  {
    path: '/vendor/user/:id/edit',
    meta: { 
      layout: "default", renderNavBar: true, renderSideBar: true
    },
    name: 'VendorUserEdit',
    component: () => import('@/pagesVendor/Users/edit.vue')
  }
]