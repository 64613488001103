export default [

    {
      path: '/excalibur/users',
      meta: { layout: "default", renderNavBar: true, renderSideBar: true },
      name: 'ExcaliburUsers',
      component: () => import('@/pages/excalibur/Users/index.vue')
    },

    {
      path: '/excalibur/user/:id/profile/',
      meta: { layout: "default", renderNavBar: true, renderSideBar: true },
      name: 'ExcaliburUserProfile',
      component: () => import('@/pages/excalibur/Users/profile.vue')
    },

    {
      path: '/excalibur/upload',
      meta: { layout: "default", renderNavBar: true, renderSideBar: true },
      name: 'ExcaliburUpload',
      component: () => import('@/pages/excalibur/3PL/upload.vue')
    },

    {
      path: '/excalibur/calldataupload',
      meta: { layout: "default", renderNavBar: true, renderSideBar: true },
      name: 'ExcaliburCallDataUpload',
      component: () => import('@/pages/excalibur/CallData/upload.vue')
    },

    {
      path: '/excalibur/callData/index',
      meta: { layout: "default", renderNavBar: true, renderSideBar: true },
      name: 'ExcaliburCallDataIndex',
      component: () => import('@/pages/excalibur/CallData/index.vue')
    },
    
  ]