export default [
  {
    path: '/vendor/dashboard',
    name: 'VendorDashboard',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true, vendor: true },
    component: () => import('@/pagesVendorDashboard/Dashboard.vue')
  },
  {
    path: '/vendor/profiling',
    name: 'VendorProfiling',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true, vendor: true },
    component: () => import('@/pagesVendorDashboard/Profiling.vue')
  },
  {
    path: '/vendor/profiling/details',
    name: 'VendorProfilingDetails',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true, vendor: true },
    component: () => import('@/pagesVendorDashboard/UPDetails.vue')
  },

  {
    path: '/vendor/upUser/status/:userId/:projectCode',
    name: 'VendorUPUserStatus',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true, vendor: true },
    component: () => import('@/pagesVendorDashboard/UPUserStatus.vue')
  },
  {
    path: '/vendor/upUsers',
    name: 'VendorUPUsers',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true, vendor: true },
    component: () => import('@/pagesVendorDashboard/UPUsers.vue')
  },
  {
    path: '/vendor/userActivity',
    name: 'VenderUserActivity',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true, vendor: true },
    component: () => import('@/pagesVendorDashboard/UserActivity.vue')
  },
  {
    path: '/vendor/userActivity/:userId/:projectId/view',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true, vendor: true },
    name: 'UserActivityView',
    component: () => import('@/pagesVendorDashboard/UserActivityView.vue')
  },
  {
    path: '/vendor/register',
    name: 'VendorRegister',
    meta: { layout: "default", guest: true, noScroll: true, renderNavBarGuest: true },
    component: () => import('@/pagesVendorDashboard/Registration.vue')
  },
]