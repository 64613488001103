import excaliburPaths from "./excaliburPaths.js"
import commonPaths from "./commonPaths.js"
import buyerPaths from "./buyerPaths.js"
import mobilePaths from "./mobilePaths.js"
import vendorPaths from './vendorPaths.js'
import vendorDashboardPaths from './vendorDashboardPaths.js'
import mobilePathsV2 from './mobilePathsV2.js'
import websitePaths from './websitePaths.js'

export default [...excaliburPaths, ...commonPaths, ...buyerPaths, ...mobilePaths, ...vendorPaths, ...vendorDashboardPaths, ...mobilePathsV2, ...websitePaths,
  {
    path: '/dashboard/users',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true },
    name: 'DashboardUsers',
    component: () => import('@/pagesDashboard/Users/index.vue')
  },
  {
    path: '/dashboard/user/create',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true },
    name: 'DashboardCreateUser',
    component: () => import('@/pagesDashboard/Users/create.vue')
  },
  {
    path: '/dashboard/:id/edit',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true },
    name: 'DashboardEditUser',
    component: () => import('@/pagesDashboard/Users/edit.vue')
  },
  {
    path: '/misc/parameters',
    meta: { layout: "default", renderNavBar: true, renderSideBar: true },
    name: 'SurveyParametersIndex',
    component: () => import('@/pagesBuyerDashboard/SurveyParameters/index.vue')
  }
];