const WebsiteMenu =  [
    {
      title: 'Enquiry', name: 'WebsiteEnquiry', icon: 'subject',
    },
    {
      title: 'Community Users', name: 'CommunityUsers', icon: 'group',
    },
  ];
  // reorder menu
  WebsiteMenu.forEach((item) => {
    if (item.items) {
      item.items.sort((x, y) => {
        let textA = x.title.toUpperCase();
        let textB = y.title.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    }
  });
  
  export default WebsiteMenu;
  