const mobileMenu = [
  {
    title: 'Users', name: 'MobileUserIndex', icon: 'group',
  },
  {
    title: 'Community Users', name: 'CommunityUsersIndex', icon: 'mdi-circle-small',
  },
  {
    title: 'Projects', name: 'MobileProjectIndex', icon: 'create',
  },
  {
    title: 'Activities', name: 'MobileActivityIndex', icon: 'view_list',
  },
  // {
  //   title: 'Rewards', name: 'MobileRewardIndex', icon: 'attach_money',
  // },
  {
    title: 'Cashout Paypal', name: 'CashoutPaypal', icon: 'attach_money',
  },
  {
    title: 'Plant Tree', name: 'CashoutPlantTree', icon: 'nature',
  },
  {
    title: 'Assign Project', name: 'MobileAssignProject', icon: 'assignment_turned_in',
  },
  {
    title: 'Assign Survey', name: 'SurveyAssign', icon: 'assignment_turned_in',
  },
  {
    title: 'User Activity', name: 'MobileUserActivityIndex', icon: 'format_shapes',
  },
  {
    title: 'User Profile', name: 'MobileUserProfileIndex', icon: 'format_shapes',
  },
  {
    title: 'Notification', name: 'MobileNotification', icon: 'add_alert',
  },
  {
    title: 'Project Code', name: 'MobileProjectCodeIndex', icon: 'code',
  },
  {
    title: 'Audience Management', name: 'MobileAudienceLookup', icon: 'create',
  },
  {
    title: 'Quality Check', name: 'MobileQualityControlIndex', icon: 'block',
  },
  {
    title: 'Social Sharing', name: 'MobileSocialSharingIndex', icon: 'share',
  },
  {
    title: 'OOH Brand Data', name: 'MobileOohBrandData', icon: 'coffee',
  },
  {
    title: 'OOH Store Data', name: 'MobileOohStoreData', icon: 'store',
  },
  {
    title: 'SMS Gateway', name: 'MobileSMSGateway', icon: 'message',
  },
  {
    title: 'Ipurchase', name: 'ipurchase', icon: 'code',
  },
];

// reorder menu
mobileMenu.forEach((item) => {
  if (item.items) {
    item.items.sort((x, y) => {
      let textA = x.title.toUpperCase();
      let textB = y.title.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }
});

export default mobileMenu;
