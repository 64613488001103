import RestResource from "./../services/dataServiceAdmin.js";
import RestResourceVD from "./../services/dataServiceVD.js";
const adminService = new RestResource();
const vendorService = new RestResourceVD()

export default [
  {
    name: 'APP_LOGOUT',
    callback: function () {
      let loggedInUser = this.$store.state.loggedInAs
      if (loggedInUser == 'VENDOR') {
        vendorService.logout()
        this.$router.replace({ name: 'Login' });
      } else {
        adminService.logout()
      }

      window.$cookies.remove('izcToken')
      this.$router.replace({ name: 'Login' });
    }
  },
];
