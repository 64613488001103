const Menu =  [
  {
    title: 'Users', name: 'BuyerUsers', icon: 'group',
  },
  {
    title: 'Projects', name: 'BuyerProjects',icon: 'movie_creation',
  },
  {
    title: 'Assign Project', name: 'BuyerAssign',icon: 'assignment_turned_in',
  },
  {
    title: 'Respondents', name: 'BuyerRespondants', icon: 'supervised_user_circle',
  },
  {
    title: 'Sync Respondents', name: 'BuyerSyncRespondents', icon: 'rotate_90_degrees_ccw',
  },
  {
    title: 'Quotations', name: 'Quotation', icon: 'money',
  },
  {
    title: 'Survey Parameters', name: 'SurveyParametersIndex', icon: 'mdi-circle-small',
  },
];
// reorder menu
Menu.forEach((item) => {
  if (item.items) {
    item.items.sort((x, y) => {
      let textA = x.title.toUpperCase();
      let textB = y.title.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }
});

export default Menu;
