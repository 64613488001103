import axios from 'axios'
import Util from "../../Util.js"

var ADMIN_BACKEND_URL = process.env.VUE_APP_ADMIN_BACKEND_URL

export default class RestResource {
  async login(data) {
    return await axios.post(`${ADMIN_BACKEND_URL}/auth/login`, data)
  }

  async logout() {
    axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')
    return await axios.post(`${ADMIN_BACKEND_URL}/auth/logout`)
  }

  async updatePassword(passwordRequest) {
    axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')
    return await axios.post(`${ADMIN_BACKEND_URL}/auth/updatePassword`, passwordRequest)
  }

  async whoAmI(data) {
    axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')
    return await axios.post(`${ADMIN_BACKEND_URL}/auth/whoAmI`, data)
  }

  async fetchUsers(params) {
    const url = `${ADMIN_BACKEND_URL}/common/user/fetchUsers`
    return await axios.post(url, params, Util.setupHeaders());
  }

  async updateUMUser(user) {
    const url = `${ADMIN_BACKEND_URL}/common/user/updateUser`
    return await axios.post(url, user, Util.setupHeaders());
  }

  async fetchOrganizations(data) {
    const url = `${ADMIN_BACKEND_URL}/common/organization/fetchOrganizations`
    return await axios.post(url, data, Util.setupHeaders())
  }

  async createOrganization(data) {
    const url = `${ADMIN_BACKEND_URL}/common/organization/createOrganization`
    return await axios.post(url, data, Util.setupHeaders())
  }

  async updateOrganization(data) {
    const url = `${ADMIN_BACKEND_URL}/common/organization/updateOrganization`
    return await axios.post(url, data, Util.setupHeaders())
  }

  async getConfig(key) {
    const url = `${ADMIN_BACKEND_URL}/common/data/getConfig?key=${key}`
    return await axios.get(url, Util.setupHeaders())
  }

  async auditLog(data) {
    return await axios.post(`${ADMIN_BACKEND_URL}/audit/create`, data)
  }

  async listESmsSmsLog(data) {
    const url = `${ADMIN_BACKEND_URL}/log/sms/esms`
    return await axios.post(url, data, Util.setupHeaders())
  }

  async listNexmoSmsLog(data) {
    const url = `${ADMIN_BACKEND_URL}/log/sms/nexmo`
    return await axios.post(url, data, Util.setupHeaders())
  }

  async listSESEmailLog(data) {
    const url = `${ADMIN_BACKEND_URL}/log/email/ses`
    return await axios.post(url, data, Util.setupHeaders())
  }

  async betterUpTimeProxyPass(params) {        
    const url = `${ADMIN_BACKEND_URL}/proxyPass/betterUpTime`
    return axios.post(url, params, Util.setupHeaders())
  }

  async nexmoCreditLookup() {        
    const url = `${ADMIN_BACKEND_URL}/proxyPass/nexmoCreditLookup`
    return axios.post(url, {}, Util.setupHeaders())
  }

  async esmsCreditLookup() {        
    const url = `${ADMIN_BACKEND_URL}/proxyPass/esmsCreditLookup`
    return axios.post(url, {}, Util.setupHeaders())
  }
}